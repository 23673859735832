import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  SectionHead,
  HalvedContent,
  ContentBox,
  List,
  Paragraphs,
  Grid,
  Button
} from 'src/components';
import { Card, CardHeader } from 'src/components/Cards';

import content from 'src/content/unsere-loesungen/absicherung-fuer-unternehmen';

const AbsicherungFuerUnternehmenPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: '75'
    }}
  >
    <section>
      <SectionHead headline={content.sectionHead.headline}>
        <Paragraphs items={content.sectionHead.paragraphs} />
      </SectionHead>
      <Grid>
        {content.topCards.map((card, index) => (
          <Card key={index}>
            <CardHeader headline={card.title} smallFontSize />
            <p>{card.text}</p>
            <Button href={card.href} variant="gray-blue" fullWidth>
              Mehr erfahren
            </Button>
          </Card>
        ))}
      </Grid>
      {content.halvedContent.map((item, index) => (
        <HalvedContent key={index} align="center" swapped={index % 2 === 0}>
          <BackgroundImage
            fluid={
              data[`halvedContentBackground${index + 1}`].childImageSharp.fluid
            }
          />
          <ContentBox background={item.background} headline={item.headline}>
            <div className="text-gray-blue">
              <List items={item.listItems} />
            </div>
          </ContentBox>
        </HalvedContent>
      ))}
    </section>
  </MainLayout>
);

export default AbsicherungFuerUnternehmenPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-orange-hair-with-glases-wide.jpg" }
    ) {
      ...image
    }
    halvedContentBackground1: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-with-laptop-and-papers.jpg" }
    ) {
      ...image
    }
    halvedContentBackground2: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-in-office-street-in-background.jpg" }
    ) {
      ...image
    }
  }
`;
