const content = {
  title: 'Absicherung für Unternehmen',
  headvisualHeadline: 'Absicherung für Unternehmen',
  sectionHead: {
    headline: 'Rundum absichern – und als Arbeitgeber punkten',
    paragraphs: [
      'Unternehmer müssen sich heute immer mehr auf neue Märkte, verändertes Kundenverhalten und auch auf eine neue Generation von Mitarbeitern einstellen. Zu all diesen Herausforderungen kommt Ihre unternehmerische Verantwortung.',
      'Neben der eigenen Absicherung und dem Schutz von Unternehmens- und Sachwerten ist es für Sie wichtig, motivierte und qualifizierte Mitarbeiter zu finden und zu halten. Da ist es gut einen Partner an seiner Seite zu haben, der Ihren gesamten Betrieb im Blick hat und genau weiß, was Sie brauchen. '
    ]
  },
  topCards: [
    {
      title: 'Keyperson-Versicherung',
      text:
        'Mit der Keyperson-Versicherung schützen Sie die Unverzichtbaren in Ihrem Unternehmen.',
      href:
        '/unsere-loesungen/absicherung-fuer-unternehmen/absicherung-ihres-unternehmens#keyperson-versicherung'
    },
    {
      title: 'Betriebsunterbrechungs-Versicherung',
      text:
        'Damit die Existenz Ihres Betriebs gesichert ist - auch wenn Sie ausfallen.',
      href:
        '/unsere-loesungen/absicherung-fuer-unternehmen/absicherung-ihres-unternehmens#betriebsunterbrechungsmoeglichkeiten'
    },
    {
      title: 'Liquidationsversicherung',
      text:
        'Vorsorgen für den Fall der Fälle - Unternehmensschließung trotz bAV',
      href:
        '/unsere-loesungen/absicherung-fuer-unternehmen/absicherung-ihres-unternehmens#liquidationsversicherung'
    }
  ],
  halvedContent: [
    {
      headline: 'Absicherung leitender Angestellter/GGF',
      background: 'light-pink',
      listItems: [
        {
          href:
            '/unsere-loesungen/absicherung-fuer-leitende-angestellte/entgeltumwandlung-laufende-beitraege',
          text: 'Entgeltumwandlung'
        },
        {
          href:
            '/unsere-loesungen/absicherung-fuer-leitende-angestellte/entgeltumwandlung-laufende-beitraege',
          text: 'Laufende Beiträge'
        },
        {
          href:
            '/unsere-loesungen/absicherung-fuer-leitende-angestellte/entgeltumwandlung-laufende-beitraege',
          text: 'Einmalbeiträge'
        },
        {
          href:
            '/unsere-loesungen/absicherung-fuer-leitende-angestellte/entgeltumwandlung-laufende-beitraege',
          text: 'Leistungszusage'
        }
      ]
    },
    {
      headline: 'Absicherung Ihrer Mitarbeiter',
      background: 'light-blue',
      listItems: [
        {
          href:
            '/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge',
          text: 'betriebliche Altersvorsorge (bAV)'
        },
        {
          href:
            '/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz',
          text: 'betrieblicher Einkommensschutz (EKS)'
        },
        {
          href:
            '/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-krankenversicherung',
          text: 'betriebliche Krankenversicherung (BKV)'
        },
        {
          href:
            '/unsere-loesungen/absicherung-fuer-mitarbeiter/weitere-absicherungsmoeglichkeiten',
          text: 'weitere Absicherungsmöglichkeiten'
        }
      ]
    }
  ]
};

export default content;
